import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import CustomTooltip from './toolTip';
const KeepLineupCheckbox = () => {
  const walletAddress = localStorage.getItem('walletAddress');
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';


  // Fetch initial status
  useEffect(() => {
    const fetchAutoKeepLineupStatus = async () => {
      try {
        const response = await axios.get(
          `${linkUrl}/wp-json/custom/v1/get-lineup-status?walletAddress=${walletAddress}`
        );
        setIsChecked(response.data.auto_keep_lineup === 1);
      } catch (error) {
        console.error("Error fetching lineup status:", error);
      }
    };
    fetchAutoKeepLineupStatus();
  }, [walletAddress]);

  // Handle checkbox toggle
  const handleCheckboxChange = async (event:any) => {
    const newStatus = event.target.checked ? 1 : 0;
    setIsChecked(event.target.checked);

    try {
      await axios.post(`${linkUrl}/wp-json/custom/v1/update-lineup-status`, {
        walletAddress,
        auto_keep_lineup: newStatus,
      });
    } catch (error) {
      console.error("Error updating lineup status:", error);
    }
  };

  return (
    <div className='autokeep'>
      <label className="custom-checkbox-autokeep">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {t('AutoKeep')}&nbsp;
        <CustomTooltip content={t('explanationAutoKeep')}>
  <button className="button_tooltip"><i className="fa-solid fa-circle-info"></i></button>
  </CustomTooltip>
      </label>
    </div>
  );
};

export default KeepLineupCheckbox;
